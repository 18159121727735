import { storeToRefs } from "pinia";
import {
  Material,
  ProductData,
  WishlistBaggage,
  WishlistData,
} from "ecom-types";

const version = 1;
export interface WishListData {
  version?: number;
  images?: any[];
  configuration_id?: string;
  collection_ids?: string[] | number[];
  cost?: number | string;
  engraving?: { [key: string]: string };
  slug?: string;
  sku: string;
  materials?: ProductData["materials"] | Material[];
  material_categories?: ProductData["material_categories"];
  material_categories_plp_hover_ids?: ProductData["material_categories_plp_hover_ids"];
  translations?: ProductData["translations"];
  currentTranslation?: ProductData["currentTranslation"];
  min_cost?: number;
  active_configurations_count?: number;
  vendor_model: ProductData["vendor_model"];
  product_id: number | string;
  added_on?: string | Date;
}

type RequiredFields = "sku" | "engraving";
type OptionalWishListData = Partial<Omit<WishListData, RequiredFields>> &
  Pick<WishListData, RequiredFields>;

export const useWishlist = () => {
  const generalStore = useGeneralStore();
  const generalInternalStore = useGeneralInternalStore();
  const { wishlist } = storeToRefs(generalStore);
  const { $api } = useNuxtApp();
  const { wishlistId } = useCookiesService();
  const { addToFavoritesEvent, removeFromFavoritesEvent } = useEvents();
  const { locale } = useI18n();
  const currentTranslationId =
    generalStore.getCurrentLanguage(locale.value)?.id ||
    generalStore.getDefaultLanguage?.id;

  const wishlistBadge = computed(() => {
    return (
      wishlist.value?.baggage?.reduce?.((acc: WishlistBaggage[], el) => {
        if (el.version && +el.version === +version) {
          acc.push({
            ...el,
            engraving: Array.isArray(el.engraving) ? {} : el.engraving,
          });
        }
        return acc;
      }, [] as WishlistBaggage[]) || ([] as WishlistBaggage[])
    );
  });

  const inWishlist = computed(() => {
    return (payload: OptionalWishListData) => {
      return wishlistBadge.value?.find((el) => {
        return isEqual(
          {
            sku: el.sku,
            engraving: el.engraving,
          },
          { sku: payload.sku, engraving: payload.engraving },
        );
      });
    };
  });
  async function updateWishlist(payload: WishListData) {
    if (!generalStore.isAuthenticated) {
      generalInternalStore.isSignInDrawerOpen = true;
      return;
    }
    if (!wishlistId.value) return;
    if (!payload.sku) return;
    const isExist = inWishlist.value(payload);
    const baggage = wishlistBadge.value;
    const data: {
      unique_id: WishlistData["unique_id"];
      baggage: WishlistData["baggage"];
    } = {
      unique_id: wishlistId.value,
      baggage: isExist
        ? baggage.filter(
            (el: WishlistBaggage) =>
              !isEqual(
                { sku: el.sku, engraving: el.engraving },
                { sku: payload.sku, engraving: payload.engraving },
              ),
          )
        : [...baggage, { ...payload, version }],
    };
    const payloadWithCurrentTranslation = addCurrentTranslation(
      payload,
      currentTranslationId as number,
      generalStore.getDefaultLanguage?.id as number,
    );
    if (isExist) {
      removeFromFavoritesEvent({
        product_id: payload?.product_id?.toString(),
        configuration_id: payload.configuration_id,
        product_sku: payload.vendor_model,
        product_price: payload.cost,
        collection_id: payload.collection_ids?.[0],
        product_title: payloadWithCurrentTranslation?.currentTranslation?.title,
      });
    } else {
      addToFavoritesEvent({
        product_id: payloadWithCurrentTranslation?.product_id?.toString(),
        configuration_id: payloadWithCurrentTranslation.configuration_id,
        product_sku: payloadWithCurrentTranslation.vendor_model,
        product_price: payloadWithCurrentTranslation.cost,
        collection_id: payloadWithCurrentTranslation?.collection_ids?.[0],
        product_title: payloadWithCurrentTranslation?.currentTranslation?.title,
      });
    }

    try {
      const dataWishlist = await $api.wishlist.addItem(data);

      const mapedPayload =
        dataWishlist?.data.baggage?.map(
          (el): WishlistBaggage & WishListData["engraving"] => ({
            ...el,
            engraving: Array.isArray(el.engraving) ? {} : el.engraving,
          }),
        ) || [];
      // state.wishlist = mapedPayload;

      const localStorageData =
        mapedPayload.map((el) => ({
          engraving: el.engraving as { [key: string]: string },
          sku: el.sku as string,
        })) || [];
      localStorage.setItem(
        "conf-m8ht77-wishlist",
        JSON.stringify(localStorageData),
      );

      wishlist.value = dataWishlist?.data;
    } catch (error) {
      console.error(error);
    }
  }

  return { updateWishlist, inWishlist, wishlistBadge };
};
