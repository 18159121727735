<template>
  <LazyCommonAppHeaderDiscountCompaign
    v-if="discountCampaignTopBanner && !isCheckout"
    :content="discountCampaignTopBanner.top_bar_text"
  />
  <header ref="headerEl" class="sticky top-0 z-[998] w-full bg-white">
    <CommonAppHeaderSeoNav class="visually-hidden" />
    <div class="relative border-b border-gray-60">
      <div class="container h-16 max-md:max-w-full md:h-15 3xl:max-w-[1788px]">
        <div
          class="flex h-full items-center gap-x-5 md:justify-between lg:gap-x-12.5"
        >
          <div v-if="!hideMenu" class="flex py-4">
            <UiButton
              variant="clear"
              variant-type="icon"
              size="xs"
              class="lg:hidden"
              data-menu-toggle
              aria-label="side navigation"
              @click="openBurgerMenu"
            >
              <UiIcon
                aria-hidden="true"
                :name="isOpen ? 'close' : 'menu'"
                class="w-6"
              />
            </UiButton>
            <nuxt-link
              :to="localePath('/')"
              class="hidden max-w-full flex-shrink-0 flex-grow lg:inline-block"
              @click="closeBurgerMenu"
            >
              <img
                :src="generalStore?.settings?.desktop_logo"
                :alt="
                  generalStore.settings?.company_name
                    ? `${generalStore.settings?.company_name} ${$t('headerDesktopLogo')}`
                    : 'Company logo'
                "
                class="w-[234px] min-w-[200px] object-contain"
              />
            </nuxt-link>
          </div>
          <LazyCommonAppHeaderNav
            v-if="!hideMenu && !searchOpen"
            class="hidden h-full flex-shrink-0 items-center justify-around py-4 lg:flex"
          />
          <LazyCommonAppHeaderSearchModal
            v-if="searchOpen"
            class="flex-1 max-lg:hidden"
            @close="searchOpen = false"
            @goToSearchPage="goToSearchPageHandler"
          />
          <nuxt-link
            :to="localePath('/')"
            class="flex-shrink-0 flex-grow"
            :class="[
              !hideMenu
                ? 'lg:hidden'
                : 'flex justify-center lg:mx-auto lg:justify-start',
              $route.params.id && hideMenu ? 'flex justify-center' : '',
            ]"
            @click="closeBurgerMenu"
          >
            <img
              :src="generalStore?.settings?.mobile_logo"
              :alt="
                generalStore.settings?.company_name
                  ? `${generalStore.settings?.company_name} ${$t('headerMobileLogo')}`
                  : 'Company logo'
              "
              class="w-[148px] object-contain lg:w-[155px] lg:max-w-[155px]"
            />
          </nuxt-link>

          <nuxt-link
            v-if="isCheckout"
            :to="localePath(`/cart/${generalStore.cart?.unique_id || ''}`)"
            class="ml-auto hidden items-center gap-1.5 text-xs font-250 lg:inline-flex"
          >
            <UiIcon name="arrow_left" class="size-4" />
            {{ $t("returnToBag") }}
          </nuxt-link>
          <div
            v-if="!hideMenu"
            class="flex items-center justify-end gap-x-3.5 py-4 lg:gap-x-6"
          >
            <UiButton
              v-if="!searchOpen"
              variant="clear"
              variant-type="icon"
              size="xs"
              aria-label="global-search"
              class="hidden size-6 lg:block"
              @click.prevent="openSearch"
            >
              <UiIcon aria-hidden="true" name="search" class="size-6" />
            </UiButton>
            <UiButton
              v-if="generalStore.isAuthenticated"
              variant="clear"
              variant-type="icon"
              size="xs"
              aria-label="profile"
              :to="localePath('/profile')"
              @click="closeBurgerMenu"
            >
              <UiIcon aria-hidden="true" name="user" class="size-6" />
            </UiButton>
            <UiButton
              v-else
              variant="clear"
              variant-type="icon"
              size="xs"
              aria-label="login"
              @click="openAuthDrawer"
            >
              <UiIcon aria-hidden="true" name="user" class="size-6" />
            </UiButton>
            <UiButton
              variant="clear"
              variant-type="icon"
              size="xs"
              aria-label="wishlist"
              @click="openWishlist"
            >
              <UiIcon aria-hidden="true" name="heart" class="size-6" />
            </UiButton>
            <div class="relative flex">
              <UiButton
                variant="clear"
                variant-type="icon"
                size="xs"
                aria-label="cart"
                @click="openCart"
              >
                <UiIcon aria-hidden="true" name="bag" class="size-6" />

                <ClientOnly>
                  <div
                    v-if="cartItemsQuantity"
                    class="absolute bottom-px right-px flex h-3 min-w-3 justify-center rounded-full bg-orange-80 px-px"
                  >
                    <span class="ui-text-captions font-normal text-white">{{
                      cartItemsQuantity
                    }}</span>
                  </div>
                </ClientOnly>
              </UiButton>
            </div>
          </div>
        </div>
      </div>
      <LazyCartDrawer />
      <LazyAuthDrawer />
      <LazyProfileWishlistDrawer />
    </div>
  </header>
  <LazyCommonAppHeaderSearchModalMobile
    v-if="!isCheckout && !isCart"
    class="lg:hidden"
    @setSearchLength="setSearchLength"
    @close="searchOpen = false"
    @goToSearchPage="goToSearchPageHandler"
  />

  <CommonAppHeaderBurgerModalLanding
    v-model="isOpen"
    :logo-src="generalStore?.settings?.desktop_logo ?? ''"
  />
</template>

<script setup lang="ts">
import { MaybeElement } from "@vueuse/core";

const localePath = useLocalePathPolyfill();
const generalStore = useGeneralStore();
const checkoutStore = useCheckoutStore();
const generalInternalStore = useGeneralInternalStore();
const route = useRoute();
const { cartItemsQuantity } = useCart();
const { goToSearchPageHandler } = useGoToSearchPage();

const searchOpen = ref(false);
const searchedData = ref<string>("");
const headerEl = ref<MaybeElement>();
const isOpen = ref(false);

const { height } = useElementSize(headerEl);

watch(height, () => {
  setCustomProperty("--header-height", `${height.value}px`);
});

const isCheckout = computed(() => {
  return route.path.includes("checkout");
});

const isCart = computed(() => {
  return route.path.includes("cart");
});
const hideMenu = computed(() => {
  return isCheckout.value;
});

const discountCampaignTopBanner = computed(() => {
  return generalStore.discountCampaignsData.topBanner;
});

watch(searchedData, async (val) => {
  const el = document?.querySelector("body");
  if (!el) {
    return;
  }
  window.scrollTo(0, 0);
  await nextTick();

  val ? (el.style.overflowY = "hidden") : el.style.removeProperty("overflow-y");
});

function openBurgerMenu() {
  isOpen.value = !isOpen.value;
}

function openWishlist() {
  generalInternalStore.isSignInDrawerOpen = false;
  checkoutStore.isCartOpen = false;
  closeBurgerMenu();
  generalStore.isWishlistDrawerOpen = !generalStore.isWishlistDrawerOpen;
}

function openSearch() {
  closeBurgerMenu();
  searchOpen.value = !searchOpen.value;
}

function setSearchLength(data: string) {
  searchedData.value = data;
}

function closeBurgerMenu() {
  isOpen.value = false;
}

function openAuthDrawer() {
  generalStore.isWishlistDrawerOpen = false;
  checkoutStore.isCartOpen = false;
  closeBurgerMenu();
  generalInternalStore.isSignInDrawerOpen = true;
}

function openCart() {
  generalInternalStore.isSignInDrawerOpen = false;
  generalStore.isWishlistDrawerOpen = false;
  closeBurgerMenu();
  checkoutStore.isCartOpen = !checkoutStore.isCartOpen;
}
</script>
