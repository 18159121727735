<template>
  <form class="flex flex-col items-start px-9.5" @submit.prevent="onSubmit">
    <div class="mb-12.5 w-full space-y-2">
      <div class="w-full">
        <div class="mb-1 font-medium">{{ $t("emailAddress") }}</div>
        <UiInput
          v-model="signInForm.email"
          class="flex-1"
          name="email"
          :disabled="disabled"
          :placeholder="$t('enterYourEmailAddress')"
        />
      </div>
      <div class="w-full">
        <div class="mb-1 font-medium">{{ $t("password") }}</div>
        <UiPasswordField
          v-model="signInForm.password"
          class="flex-1"
          name="password"
          placeholder="••••••••••••"
          :disabled="disabled"
          autocomplete="current-password"
        />
      </div>
      <button
        class="font-normal underline"
        type="button"
        @click="$emit('changeForm', AuthDrawerEnum.FORGOT)"
      >
        {{ $t("forgotYourPassword") }}
      </button>
    </div>

    <UiButton
      :loading="isSubmitting"
      type="submit"
      color="primary"
      class="mb-4 w-full"
    >
      {{ $t("logIn") }}
    </UiButton>
    <UiButton
      color="primary"
      type="button"
      outlined
      class="w-full"
      @click="$emit('changeForm', AuthDrawerEnum.REGISTER)"
    >
      {{ $t("dontHaveAnAccount") }}&nbsp;
      <span class="underline">{{ $t("signUp") }}</span>
    </UiButton>
    <template v-if="generalStore.getFirebaseIntegration && $socialAuth.auth">
      <div class="my-5 w-full text-center font-light">
        {{ $t("orSignIn") }}
      </div>
      <SharedSocialAuth @success="successAuth" />
    </template>
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { object, string } from "yup";
import { SignInForm } from "ecom-types";
import { FetchError } from "ofetch";

import { AuthDrawerEnum } from "~/types/components.types";
const generalStore = useGeneralStore();
const generalInternalStore = useGeneralInternalStore();
const { $socialAuth } = useNuxtApp();

const route = useRoute();
const router = useRouter();
const localePath = useLocalePathPolyfill();
const { t } = useI18n();

defineProps<{
  disabled?: boolean;
}>();

const { onSignIn } = useAuth();

const initialSignInForm: SignInForm = {
  email: "",
  password: "",
};

const schema = object().shape({
  email: string().email().required().label(t("emailAddress")),
  password: string().required().label(t("password")),
});

const signInForm = reactive(
  JSON.parse(JSON.stringify(initialSignInForm)),
) as SignInForm;

const {
  handleSubmit: handleSubmitSignIn,
  resetForm: resetFormSignIn,
  setErrors: setErrorsSignIn,
  isSubmitting,
} = useForm({
  initialValues: initialSignInForm,
  validationSchema: schema,
});

async function successAuth() {
  if (route.query.redirect) {
    await router.replace(localePath(route.query.redirect as string));
  } else {
    await router.replace(localePath("/profile"));
  }
}

const onSubmit = handleSubmitSignIn(async (values) => {
  try {
    await onSignIn(values);
    await Promise.all([generalStore.getCart(), generalStore.getWishList()]);
    resetFormSignIn();
    generalInternalStore.isSignInDrawerOpen = false;

    await successAuth();
  } catch (error) {
    if (error instanceof FetchError) {
      setErrorsSignIn(responseErrorToObjectErrors(error?.data?.errors));
    }
  }
});
</script>
